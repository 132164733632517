import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, OrthographicCamera } from '@react-three/drei';

import ReactAudioPlayer from 'react-audio-player';

import createAnimation from './converter';
import blinkData from './blendDataBlink.json';

import * as THREE from 'three';
import axios from 'axios';
const _ = require('lodash');

const textToVoiceAPI = 'https://backend.airtab.us'
const virtualMeAPI = 'https://virtualme.airdec.net/'
const appSlug = 'shahfarm'

function Avatar({ avatar_url, speak, setSpeak, text, setText, setAudioSource, playing }) {

  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryFace = null;
  let morphTargetDictionaryEyeLeft = null;
  let morphTargetDictionaryEyeRight = null;
  let morphTargetDictionaryEyebrow = null;
  let morphTargetDictionaryEyelash = null;
  let morphTargetDictionaryLowerTeeth = null;

  
  gltf.scene.traverse(node => {


    if(node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {


      console.log(node.name);

      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;

      if (node.name.includes("Face")) {
        morphTargetDictionaryFace = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eye_L")) {
        morphTargetDictionaryEyeLeft = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eye_R")) {
        morphTargetDictionaryEyeRight = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eyebrows")) {
        morphTargetDictionaryEyebrow = node.morphTargetDictionary;
      }
      else if (node.name.includes("Eyelashes")) {
        morphTargetDictionaryEyelash = node.morphTargetDictionary;
      }
      else if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }

    }

  });

  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;

    makeSpeech(text)
    .then( response => {

      let {blendData, filename} = response.data;

      // console.log(blendData);
      // console.log(morphTargetDictionaryFace);
      // console.log(morphTargetDictionaryEyeLeft);
      // console.log(morphTargetDictionaryEyeRight);
      // console.log(morphTargetDictionaryLowerTeeth);

      let newClips = [
        createAnimation(blendData, morphTargetDictionaryFace, 'Face'),
        createAnimation(blendData, morphTargetDictionaryEyeLeft, 'Eye_L002'),
        createAnimation(blendData, morphTargetDictionaryEyeLeft, 'Eye_L002_1'),
        createAnimation(blendData, morphTargetDictionaryEyeLeft, 'Eye_L002_2'),
        createAnimation(blendData, morphTargetDictionaryEyeRight, 'Eye_R002'),
        createAnimation(blendData, morphTargetDictionaryEyeRight, 'Eye_R002_1'),
        createAnimation(blendData, morphTargetDictionaryEyeRight, 'Eye_R002_2'),
        createAnimation(blendData, morphTargetDictionaryEyebrow, 'Eyebrows'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_1'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_2'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_3'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_4'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_5'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_6'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_7'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_8'),
        createAnimation(blendData, morphTargetDictionaryEyelash, 'Eyelashes001_9'),
        createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'TeethLower002'),
        createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'TeethLower002_1')
      ];

        
      filename = textToVoiceAPI + filename;
        
      setClips(newClips);
      setAudioSource(filename);

    })
    .catch(err => {
      console.error(err);
      setSpeak(false);

    })

  }, [speak]);

  // let idleFbx = useFBX('/idle.fbx');
  // let { clips: idleClips } = useAnimations(idleFbx.animations);

  // idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
  //   return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  // });

  // idleClips[0].tracks = _.map(idleClips[0].tracks, track => {

  //   if (track.name.includes("Head")) {
  //     track.name = "head.quaternion";
  //   }

  //   if (track.name.includes("Neck")) {
  //     track.name = "neck.quaternion";
  //   }

  //   if (track.name.includes("Spine")) {
  //     track.name = "spine2.quaternion";
  //   }

  //   return track;

  // });

  useEffect(() => {

    // let idleClipAction = mixer.clipAction(idleClips[0]);
    // idleClipAction.play();

    let blinkFace = createAnimation(blinkData, morphTargetDictionaryFace, 'Face');
    let blinkFaceAction = mixer.clipAction(blinkFace);
    blinkFaceAction.play();

    let blinkEyelashes0 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001');
    let blinkEyelashesAction0 = mixer.clipAction(blinkEyelashes0);
    blinkEyelashesAction0.play();

    let blinkEyelashes1 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_1');
    let blinkEyelashesAction1 = mixer.clipAction(blinkEyelashes1);
    blinkEyelashesAction1.play();

    let blinkEyelashes2 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_2');
    let blinkEyelashesAction2 = mixer.clipAction(blinkEyelashes2);
    blinkEyelashesAction2.play();

    let blinkEyelashes3 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_3');
    let blinkEyelashesAction3 = mixer.clipAction(blinkEyelashes3);
    blinkEyelashesAction3.play();

    let blinkEyelashes4 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_4');
    let blinkEyelashesAction4 = mixer.clipAction(blinkEyelashes4);
    blinkEyelashesAction4.play();

    let blinkEyelashes5 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_5');
    let blinkEyelashesAction5 = mixer.clipAction(blinkEyelashes5);
    blinkEyelashesAction5.play();

    let blinkEyelashes6 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_6');
    let blinkEyelashesAction6 = mixer.clipAction(blinkEyelashes6);
    blinkEyelashesAction6.play();

    let blinkEyelashes7 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_7');
    let blinkEyelashesAction7 = mixer.clipAction(blinkEyelashes7);
    blinkEyelashesAction7.play();

    let blinkEyelashes8 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_8');
    let blinkEyelashesAction8 = mixer.clipAction(blinkEyelashes8);
    blinkEyelashesAction8.play();

    let blinkEyelashes9 = createAnimation(blinkData, morphTargetDictionaryEyelash, 'Eyelashes001_9');
    let blinkEyelashesAction9 = mixer.clipAction(blinkEyelashes9);
    blinkEyelashesAction9.play();


  }, []);

  // Play animation clips when available
  useEffect(() => {

    if (playing === false)
      return;
    
    _.each(clips, clip => {
        let clipAction = mixer.clipAction(clip);
        clipAction.setLoop(THREE.LoopOnce);
        clipAction.play();

    });

  }, [playing]);


  
  // useEffect(() => {
  //   setText(`Hi, my name is Dr. Ali.`);
  //   setSpeak(true);
  // }, []);
  
  useFrame((state, delta) => {
    mixer.update(delta);
  });


  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}


function makeSpeech(text) {
  // const fetchData = async () => {
  //   const response = await axios.post(textToVoiceAPI + '/talk/', { 'text': text });
  //   return response.data;
  // }
  return axios.post(textToVoiceAPI + '/talk/', { 'text': text, 'voice': 'en-US-JennyNeural' });
}

const STYLES = {
  area: {position: 'absolute', top:'10px', left: '10px', zIndex: 500},
  text: {margin: '0px', width:'400px', borderRadius: '10px', padding: '5px', background: 'rgba(255,255,255,.8)', color: '#000', fontSize: '1.2em', border: 'none'},
  disabled: {opacity: 0.5},
  speak: {padding: '10px', marginTop: '5px', display: 'block', color: '#fff', background: '#222222', border: 'None'},
  area2: {position: 'absolute', top:'5px', right: '15px', zIndex: 500},
  label: {color: '#777777', fontSize:'0.8em'},
  classic: {position: 'absolute', top:'10px', right: '10px', zIndex: 500},
  init: {position: 'absolute', top:'10px', left: '50%', transform: 'translate(-50%)', zIndex: 500},
  introduce: {padding: '20px 40px 20px 40px', display: 'block', color: '#fff', borderRadius: '100px',  textDecoration: 'none', fontWeight: 700, fontSize: '18px', textTransform: 'uppercase', background: 'linear-gradient(60deg, #16aef3, #00bcd4)', border: 'None'},
  chatHistory: {width:'400px', borderRadius: '10px', background: 'rgba(255,255,255,.8)', whiteSpace: 'pre-line', height: '200px', opacity: '0.8', overflow: 'auto'},
  chatHistoryWrapper: {position: 'absolute', bottom:'10px', left: '10px', zIndex: 500}
}

function App() {

  const audioPlayer = useRef();
  const messagesEndRef = useRef(null);

  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState('');
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [uuid, setUuid] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [visible, setVisible] = useState(true);
  const [chatArray, setChatArray] = useState([]);

  const today = new Date();

  function initSpeech() {
    setVisible((prev) => !prev);
    setText(`Hi. I am Shirley Shahlapour, the CEO of Shah Farm which has built its greenhouses on 70+ acres of land located in Berryville, historic Clarke County Virginia.
    We are located 45 minutes from Upperville and Middleburg, 15 minutes from Winchester, VA, and 45 minutes from Washington DC.
    I'd like to answer your questions about our business, So feel free to ask.`);
    // setText(`Hi, my name is Dr. Ali Beheshti.`);
    setSpeak(true);
  }

  function ask() {
    const fetchData = async () => {
      setFetching(true);
      const response = await axios.post(virtualMeAPI + 'chat/',
        {
          'app_slug': appSlug,
          'message': text,
          'uuid': uuid
        }
      );
      
      setChatArray(oldArray => [`[${today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()}] You: ` + text + '\n\n', ...oldArray]);

      setText(response.data.respond.answer);
      setSpeak(true);
      setFetching(false);
    }
    fetchData().catch(console.error);
  }

  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
  }

  // Player is read
  function playerReady(e) {
    setChatArray(oldArray => [`[${today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()}] AI: ` + text + '\n\n', ...oldArray]);
    setText('');
    
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  }

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const response = await axios.get(virtualMeAPI + 'get-app/' + appSlug + '/');
      setUuid(response.data.app.uuid);
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])

  return (
    <div className="full">
      <div style={STYLES.area}>
        <textarea placeholder='Type your Question/Response here...' rows={10} maxLength={500} type="text" style={STYLES.text} value={text} onChange={(e) => setText(e.target.value)} disabled={speak || fetching} />
        <button onClick={() => ask()} style={STYLES.speak}> { fetching ? 'Wait for Answer...' : speak ? 'Recording & Running...' : 'Send' }</button>
      </div>

      {visible && (
        <div style={STYLES.init}>
          <button onClick={() => initSpeech()} style={STYLES.introduce}>Start</button>
        </div>
      )}
      
      <div style={STYLES.classic}>
        <a target='_blank' href="https://shah.farm" style={STYLES.speak}>Go Back to Classic Website</a>
      </div>

      <div style={STYLES.chatHistoryWrapper}>
        <h3 style={{textAlign: 'left'}}>Chat History</h3>
        <div ref={messagesEndRef} style={STYLES.chatHistory}>{chatArray}</div> 
      </div>

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlay={playerReady}
        
      />
      
      {/* <Stats /> */}
    <Canvas dpr={0.75} onCreated={(ctx) => {
        ctx.gl.physicallyCorrectLights = true;
      }}>

    {/* <ambientLight /> */}
    {/* <pointLight position={[0, 0, 20]} /> */}
    {/* <pointLight position={[-10, -10, 0]} /> */}
    <pointLight position={[5, 3, 10]} intensity={20} />
    <pointLight position={[-5, 3, 10]} intensity={20} />

      <OrthographicCamera 
      makeDefault
      zoom={2000}
      position={[0, 1.65, 1]}
      />

      {/* <OrbitControls
        target={[0, 1.65, 0]}
      /> */}

      {/* <Suspense fallback={null}>
        <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
      </Suspense> */}

      <Suspense fallback={null}>
        <Bg />
      </Suspense>

      <Suspense fallback={null}>
          <Avatar 
            avatar_url="glb/shirley-avatar-test-3.glb"
            speak={speak}
            setSpeak={setSpeak}
            text={text}
            setText={setText}
            setAudioSource={setAudioSource}
            playing={playing}
            />
      </Suspense>
  </Canvas>
  <Loader dataInterpolation={(p) => `Loading... please wait`}  />
  </div>
  )
}

function Bg() {
  
  const texture = useTexture('/images/gh.jpg');

  return(
    <mesh position={[0, 1.5, -1]} scale={[1.2, 1, 1]}>
      <planeBufferGeometry />
      <meshBasicMaterial map={texture} />

    </mesh>
  )

}

export default App;
